@use "../etc/env" as *;

/*Fonts*/
$fontsBaseURL: "#{$s3BaseURL}assets/fonts/";

@font-face {
	font-family: "Futura-ExtraBold";
	src: url("#{$fontsBaseURL}FuturaExtraBold/FuturaExtraBold.eot");
	src: url("#{$fontsBaseURL}FuturaExtraBold/FuturaExtraBold.eot?#iefix") format("embedded-opentype"),
	url("#{$fontsBaseURL}FuturaExtraBold/FuturaExtraBold.otf") format("opentype"),
	url("#{$fontsBaseURL}FuturaExtraBold/FuturaExtraBold.svg") format("svg"),
	url("#{$fontsBaseURL}FuturaExtraBold/FuturaExtraBold.ttf") format("truetype"),
	url("#{$fontsBaseURL}FuturaExtraBold/FuturaExtraBold.woff") format("woff"),
	url("#{$fontsBaseURL}FuturaExtraBold/FuturaExtraBold.woff2") format("woff2");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "Lithos-Bold";
	src: url("#{$fontsBaseURL}LithosBold/LithosBold.eot");
	src: url("#{$fontsBaseURL}LithosBold/LithosBold.eot?#iefix") format("embedded-opentype"),
	url("#{$fontsBaseURL}LithosBold/LithosBold.otf") format("opentype"),
	url("#{$fontsBaseURL}LithosBold/LithosBold.svg") format("svg"),
	url("#{$fontsBaseURL}LithosBold/LithosBold.ttf") format("truetype"),
	url("#{$fontsBaseURL}LithosBold/LithosBold.woff") format("woff"),
	url("#{$fontsBaseURL}LithosBold/LithosBold.woff2") format("woff2");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "Eurostile";
	src: url("#{$fontsBaseURL}eurostile/EurostileLTStd.otf") format("opentype");
}

@font-face {
	font-family: "Praktika Regular";
	src: url("#{$fontsBaseURL}praktika_complete/praktikaregular-webfont.woff2") format("woff2"),
	url("#{$fontsBaseURL}praktika_complete/praktikaregular-webfont.woff") format("woff");
}

@font-face {
	font-family: "Praktika Regular Italic";
	src: url("#{$fontsBaseURL}praktika_complete/praktikaregularitalic-webfont.woff2") format("woff2"),
	url("#{$fontsBaseURL}praktika_complete/praktikaregularitalic-webfont.woff") format("woff");
}

@font-face {
	font-family: "Praktika Condensed";
	src: url("#{$fontsBaseURL}praktika_complete/PraktikaCondensed.otf") format("opentype"),
	url("#{$fontsBaseURL}praktika_complete/PraktikaCondensed.woff2") format("woff2"),
	url("#{$fontsBaseURL}praktika_complete/PraktikaCondensed.woff") format("woff");
}

@font-face {
	font-family: "Praktika Medium";
	src: url("#{$fontsBaseURL}praktika_complete/praktikamedium-webfont.woff2") format("woff2"),
	url("#{$fontsBaseURL}praktika_complete/praktikamedium-webfont.woff") format("woff");
}

@font-face {
	font-family: "Praktika Medium Italic";
	src: url("#{$fontsBaseURL}praktika_complete/praktikamediumitalic-webfont.woff2") format("woff2"),
	url("#{$fontsBaseURL}praktika_complete/praktikamediumitalic-webfont.woff") format("woff");
}

@font-face {
	font-family: "Praktika Medium Condensed";
	src: url("#{$fontsBaseURL}praktika_complete/PraktikaMediumCondensed.otf") format("opentype"),
	url("#{$fontsBaseURL}praktika_complete/PraktikaMediumCondensed.woff2") format("woff2"),
	url("#{$fontsBaseURL}praktika_complete/PraktikaMediumCondensed.woff") format("woff");
}

@font-face {
	font-family: "Praktika Light";
	src: url("#{$fontsBaseURL}praktika_complete/praktikalight-webfont.woff2") format("woff2"),
	url("#{$fontsBaseURL}praktika_complete/praktikalight-webfont.woff") format("woff");
}

@font-face {
	font-family: "Praktika Light Italic";
	src: url("#{$fontsBaseURL}praktika_complete/praktikalightitalic-webfont.woff2") format("woff2"),
	url("#{$fontsBaseURL}praktika_complete/praktikalightitalic-webfont.woff") format("woff");
}

@font-face {
	font-family: "Praktika Bold";
	src: url("#{$fontsBaseURL}praktika_complete/praktikabold-webfont.woff2") format("woff2"),
	url("#{$fontsBaseURL}praktika_complete/praktikabold-webfont.woff") format("woff");
	font-weight: normal;
}

@font-face {
	font-family: "Praktika Bold Condensed";
	src: url("#{$fontsBaseURL}praktika_complete/PraktikaBoldCondensed.otf") format("opentype"),
	url("#{$fontsBaseURL}praktika_complete/PraktikaBoldCondensed.woff2") format("woff2"),
	url("#{$fontsBaseURL}praktika_complete/PraktikaBoldCondensed.woff") format("woff");
	font-weight: normal;
}

@font-face {
	font-family: "Praktika Bold Extended";
	src: url("#{$fontsBaseURL}praktika_complete/praktikaboldextended-webfont.woff2") format("woff2"),
	url("#{$fontsBaseURL}praktika_complete/praktikaboldextended-webfont.woff") format("woff");
	font-weight: normal;
}

@font-face {
	font-family: "Praktika Extra Bold";
	src: url("#{$fontsBaseURL}praktika_complete/PraktikaExtraBold.otf") format("opentype"),
	url("#{$fontsBaseURL}praktika_complete/PraktikaExtraBold.woff2") format("woff2"),
	url("#{$fontsBaseURL}praktika_complete/PraktikaExtraBold.woff") format("woff");
	font-weight: normal;
}

@font-face {
	font-family: "Praktika Extra Bold Extended";
	src: url("#{$fontsBaseURL}praktika_complete/PraktikaExtraBoldExtended.otf") format("opentype"),
	url("#{$fontsBaseURL}praktika_complete/PraktikaExtraBoldExtended.woff2") format("woff2"),
	url("#{$fontsBaseURL}praktika_complete/PraktikaExtraBoldExtended.woff") format("woff");
	font-weight: normal;
}

@font-face {
	font-family: "Praktika Extra Bold Extended Italic";
	src: url("#{$fontsBaseURL}praktika_complete/PraktikaExtraBoldExtendedItalic.otf") format("opentype"),
	url("#{$fontsBaseURL}praktika_complete/PraktikaExtraBoldExtendedItalic.woff2") format("woff2"),
	url("#{$fontsBaseURL}praktika_complete/PraktikaExtraBoldExtendedItalic.woff") format("woff");
	font-weight: normal;
}

@font-face {
	font-family: "Praktika Black";
	src: url("#{$fontsBaseURL}praktika_complete/praktikablack-webfont.woff2") format("woff2"),
	url("#{$fontsBaseURL}praktika_complete/praktikablack-webfont.woff") format("woff");
	font-weight: normal;
}

@font-face {
	font-family: "Praktika Black Extended";
	src: url("#{$fontsBaseURL}praktika_complete/praktikablackextended-webfont.woff2") format("woff2"),
	url("#{$fontsBaseURL}praktika_complete/praktikablackextended-webfont.woff") format("woff");
	font-weight: normal;
}

@font-face {
	font-family: "Praktika Bold Italic";
	src: url("#{$fontsBaseURL}praktika_complete/praktikabolditalic-webfont.woff2") format("woff2"),
	url("#{$fontsBaseURL}praktika_complete/praktikabolditalic-webfont.woff") format("woff");
	font-weight: normal;
}

@font-face {
	font-family: "Praktika Black Italic";
	src: url("#{$fontsBaseURL}praktika_complete/praktikablackitalic-webfont.woff2") format("woff2"),
	url("#{$fontsBaseURL}praktika_complete/praktikablackitalic-webfont.woff") format("woff");
	font-weight: normal;
}

@font-face {
	font-family: "LHFConvectaConvex";
	src: url("#{$fontsBaseURL}convecta/LHFConvectaConvex.eot");
	src: url("#{$fontsBaseURL}convecta/LHFConvectaConvex.eot?#iefix") format("embedded-opentype"),
	url("#{$fontsBaseURL}convecta/LHFConvectaConvex.woff2") format("woff2"),
	url("#{$fontsBaseURL}convecta/LHFConvectaConvex.woff") format("woff"),
	url("#{$fontsBaseURL}convecta/LHFConvectaConvex.ttf") format("truetype");
}

@font-face {
	font-family: "LHFConvectaBase";
	src: url("#{$fontsBaseURL}convecta/LHFConvectaBase.eot");
	src: url("#{$fontsBaseURL}convecta/LHFConvectaBase.eot?#iefix") format("embedded-opentype"),
	url("#{$fontsBaseURL}convecta/LHFConvectaBase.woff2") format("woff2"),
	url("#{$fontsBaseURL}convecta/LHFConvectaBase.woff") format("woff"),
	url("#{$fontsBaseURL}convecta/LHFConvectaBase.ttf") format("truetype");
}

@font-face {
	font-family: "LHFConvectaFull";
	src: url("#{$fontsBaseURL}convecta/LHFConvectaFull.eot");
	src: url("#{$fontsBaseURL}convecta/LHFConvectaFull.eot?#iefix") format("embedded-opentype"),
	url("#{$fontsBaseURL}convecta/LHFConvectaFull.woff2") format("woff2"),
	url("#{$fontsBaseURL}convecta/LHFConvectaFull.woff") format("woff"),
	url("#{$fontsBaseURL}convecta/LHFConvectaFull.ttf") format("truetype");
}

@font-face {
	font-family: "Thuner";
	src: url("#{$fontsBaseURL}thuner/Thuner.otf") format("opentype"),
	url("#{$fontsBaseURL}thuner/Thuner.woff2") format("woff2"),
	url("#{$fontsBaseURL}thuner/Thuner.woff") format("woff");
}

@font-face {
	font-family: "New Spirit Bold";
	src: url("#{$fontsBaseURL}NewSpirit/NewSpiritBold.otf") format("opentype");
	font-weight: normal;
}

@font-face {
	font-family: "New Spirit Bold Condensed";
	src: url("#{$fontsBaseURL}NewSpirit/NewSpiritBoldCondensed.otf") format("opentype");
	font-weight: normal;
}

@font-face {
	font-family: "New Spirit Medium";
	src: url("#{$fontsBaseURL}NewSpirit/NewSpiritMedium.otf") format("opentype");
	font-weight: normal;
}

@font-face {
	font-family: "Groovy Script";
	src: url("#{$fontsBaseURL}GroovyScript/GroovyScript.otf") format("opentype");
	font-weight: normal;
}

@font-face {
	font-family: "Groovy Script Extrude";
	src: url("#{$fontsBaseURL}GroovyScript/GroovyScriptExtrude.otf") format("opentype");
	font-weight: normal;
}