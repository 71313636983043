@use "../../../../../styles/etc/env" as *;

.represent-every-day-photo-modal {
    * {
        border: none !important;
    }

    .modal-title,
    .modal-footer {
        display: none;
    }

    .modal-header {
        padding-left: 0;
        padding-right: 0;
    }

    .modal-content {
        background-color: transparent;
        border: none;
    }

    .btn-close {
        filter: invert(100%);
        opacity: 1;
        transform: scale(1.5);
        transform-origin: center;
        background-image: url("#{$s3BaseURL}assets/custom_pages/represent-every-day/x-lg.svg");

        &:focus {
            box-shadow: none;
        }
    }

    .modal-body {
        border: none;
        padding: .75rem !important;
        background: linear-gradient(70deg, #ffc72c 35%, #7d98c1 0);
    }
}