// Fix horizontal scroll on blank slate pages, such as /schedule
div[class*="css_pageContent__"] {
    overflow-x: hidden;
}

// For some reason element with class .collapse sets
// visibility to 'collapse' and all text becomes hidden
.accordion {
    .show {
        .accordion-body {
            visibility: initial;
        }
    }
}